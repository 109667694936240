export default {
  // InvoicePositionType
  Trade: "Trade",
  Delivery: "Delivery",
  // InvoiceState
  Open: "Open",
  Invoiced: "Invoiced",
  Payed: "Payed",
  Problem: "Problem",
};
