import {
  MESSAGE_PLACEHOLDER_PRODUCT,
  MESSAGE_PLACEHOLDER_USERNAME,
} from "~/constants/shopConstants";

export default {
  messageCounterOffer: `User ${MESSAGE_PLACEHOLDER_USERNAME} send you a new offer to your trade!`,
  messageDeclineOffer: `User ${MESSAGE_PLACEHOLDER_USERNAME} declined the trade.`,
  messageAcceptOfferPartial: `User ${MESSAGE_PLACEHOLDER_USERNAME} has accepted the trade! Check it out if you wanna accept aswell!`,
  messageAcceptOfferFull: `All parts have accepted the trade ${MESSAGE_PLACEHOLDER_PRODUCT}! Choose your delivery method right now!`,
  messageNewOffer: `There is a new Offer for your open Request!`,
  messageAcceptTransportFull: `All parts have accepted the delivery bid. Check the overview!`,
};
