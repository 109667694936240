export default {
  // InvoicePositionType
  Trade: "Handel",
  Delivery: "Lieferung",
  // InvoiceState
  Open: "Offen",
  Invoiced: "Abgerechnet",
  Payed: "Bezahlt",
  Problem: "Problem",
};
