export default {
  submissionErrorSingle: "There was one error with your submission",
  submissionErrorMultiple: "There were COUNT erros with your submission",
  loadingErrorSingle: "There was one error while loading the data",
  loadingErrorMultiple: "There were COUNT errors while loading the data",
  oops: "Ooops!",
  somethingWentWrong: "Something went wrong",
  allFieldsRequired: "Please provide all fields",
  pageNotFound: "Page not found",
  pageNotFoundText: "Sorry, we couldn't find the page you're looking for.",
  unexpectedError: "Unexpected Error",
  unknownError: "Unknown Error",
  tradeProductUnavailable: "The advertisment is no longer available",
  transportRequestUnavailable: "The request is no longer available",
};
