export default {
  save: "Save",
  confirm: "Confirm",
  back: "Back",
  yes: "Yes",
  cancel: "Cancel",
  confirmDeactivationTitle: "Confirm deactivation?",
  confirmDeactivationText:
    "Are you sure you want to deactivate your account and delete all user specific data? This operation cannot be undone!",
  confirmDeactivationButton: "Yes, delete my account",
  password: "Password",
  passwordChangeSuccess: "Password changed successfully!",
  changePassword: "Change password",
  changePasswordText: "Update your password associated with your account.",
  currentPassword: "Current password",
  newPassword: "New password",
  confirmPassword: "Confirm password",
  deleteAccount: "Delete account",
  deleteAccountText:
    "No longer want to use our service? You can delete your account here. This action is not reversible. All information related to this account will be deleted permanently.",
  companyInformation: "Company Information",
  userInformation: "User Information",
  companyInformationText: "You can change company information here.",
  userInformationText: "you can change user information here.",
  allowedFileTypes: "JPG, GIF or PNG. 1MB max.",
  companyName: "Company Name",
  street: "Street",
  place: "Place",
  country: "Country",
  zip: "ZIP",
  vat: "VAT Registration No",
  email: "E-Mail",
  uploadAFile: "Upload a file",
  account: "Account",
  users: "Users",
  userUpdatedSuccessfully: "User updated successfully!",
  userCreatedSuccessfully: "User created successfully!",
  companyAddressUpdatedSuccessfully: "Address updated successfully",
  companyAddressCreatedSuccessfully: "Address created successfully!",
  userAccounts: "User Accounts",
  forename: "Forename",
  lastname: "Lastname",
  position: "Position",
  edit: "Edit",
  active: "Active",
  isPublic: "Is Public",
  editUser: "Edit User",
  registerNewUser: "Register new User",
  editCompanyAddress: "Edit Address",
  registerNewCompanyAddress: "Create new Address",
  shop: "Shop",
  connect8trading: "Connect8 Trading",
  openProductRequests: "Open Product Requests",
  tradeOverview: "Trade Overview",
  tradeDelivery: "Trade Delivery",
  tradeComplete: "Trade Complete",
  newProductRequest: "New Product Request",
  connect8Logistic: "Connect8 Logistics",
  openLogisticRequests: "Open Delivery Requests",
  myLogisticRequests: "My Requests",
  myLogisticOffers: "My Offers",
  currentLogistics: "Current Transports",
  doneLogistics: "Done Transports",
  pastLogistics: "Past Transports",
  faq: "FAQ",
  management: "Administration",
  salesdashboard: "Dashboard",
  invoicesoverview: "Invoices",
  logout: "Logout",
  tasks: "Tasks",
  taskschema: "Taskschema",
  registerUser: "Register User",
  yourQuickfilters: "Your Quickfilters",
  addressManagement: "Address Management",
  deliveryAddress: "Delivery Address",
  billingAddress: "Billing Address",
  loadingAddress: "Loading Address",
  deliveryAddresses: "Delivery Addresses",
  billingAddresses: "Billing Addresses",
  loadingAddresses: "Loading Addresses",
  submit: "Submit",
  loading: "Loading",
  processing: "Processing",
  path: "Path",
  processingData: "Processing Data",
  emailSentSuccessfully: "Email was sent successfully",
  paymentMethod: "Paymentmethods",
  incoterm: "Incoterm",
  loadingDate: "Loading Date",
  loadingDateFrom: "Loading Date from",
  loadingDateTo: "Loading Date to",
  deliveryDate: "Delivery Date",
  deliveryDateFrom: "Delivery Date from",
  deliveryDateTo: "Delivery Date to",
  state: "State",
  price: "Price",
  message: "Message",
  faqOffer: "Read our FAQ to offers",
  faqDelivery: "Read our FAQ to delivery",
  faqDone: "Read our FAQ to completed trades",
  goToOverview: "Go to overview",
  desiredPrice: "Desired Price",
  description: "Description",
  send: "Send",
  selfDelivery: "Self",
  connect8Delivery: "Connect8 Logistics",
  tradeCounter: "Counter",
  tradeDecline: "Decline",
  tradeAccept: "Accept",
  close: "Close",
  existingOfferText:
    "You already created an offer. Go to the overview to create more.",
  confirmPaymentMethodActivationTitle: "Confirm paymentmethod?",
  confirmPaymentMethodActivationText:
    "By accepting this dialog, you agree to the payment agreement and the payment model. You can revoke this at any time as stated in the payment agreement.",
  confirmPaymentMethodDeletionTitle: "Delete paymentmethod?",
  confirmPaymentMethodDeletionText:
    "Are you sure that you want to delete the paymentmethod? This operation cannot be undone!",
  existingOfferTextForMyTradeProduct:
    "There are offers for this item. Go to the overview to check them out.",
  noOfferText: "There are no offers yet!",
  default: "Default",
  isemailnotification: "E-Mail notifications",
  tutorial: "Tutorial",
  loadingplace: "Loadingplace",
  unloadingplace: "Unloadingplace",
  goods: "Goods",
  details: "Details",
  makeOffer: "Make Offer",
  firststep: "First steps",
  firststepdescription:
    "Here are some first steps - some are necessary for you to get full access to all functions.",
  addressType: "Addresstype",
  noPaymentMethodAvailableCantBid:
    "There is no payment method in your company account. Please provide a payment method before you can start bidding.",
  validDateUntil: "Offer valid until",
  offerNotValidAnymore: "This offer is not valid anymore",
  no: "No",
  productCategory: "product category",
  quantityUnit: "quantity/unit",
  goodsValue: "goods value",
  weightNetGross: "weight (net/gross)",
  company: "company",
  contactPerson: "contact person",
  createdAt: "created at",
  lastPrice: "current price",
  notificationFrom: "Notification from",
  hello: "Hello",
  invitationText:
    "You were invited to trade on Connect8! Just click the link and you will be forwarded to the product. If you got no Account yet, you have to register first.",
  inviteToConnect8: "Invitation to",
  source: "source",
  currentPrice: "current price",
  currentPriceTotal: "current price total",
  validUntil: "valid until",
  loadingFrom: "loading from",
  loadingTo: "loading to",
  deliveryFrom: "delivery from",
  deliveryTo: "delivery to",
  weightNet: "weight net",
  weightGross: "weight gross",
  quantity: "quantity",
  unit: "unit",
  companyDetails: "Companydetails",
  homepage: "Homepage",
  videoURL: "Video ID",
  shortDescription: "Short description",
  offer: "offer",
  offers: "offers",
  noResultFilter: "No results... Try expanding the search",
  noResult: "No data",
  actualInvoices: "Open Invoices",
  archivedInvoices: "Archived Invoices",
  problemInvoices: "Not billable Invoices",
  activateCompany: "Activate Company",
  joinConnect8: "Contact following Person",
  invoiceType: "type",
  date: "date",
  amount: "amount",
  tax: "tax",
  taxAmount: "tax amount",
  Invoices: "Invoices",
  InvoicesDescription: "Here you can find all the invoices of your company.",
  text2FA:
    "Two-Factor Authentication (2FA) is a security mechanism that adds an extra layer of protection to secure your online accounts. In addition to the password you use, 2FA requires a second verification step. This could be a one-time password sent to your mobile phone, an app like Google Authenticator, or a physical security device.",
  security2FA:
    "2FA makes it more difficult for potential attackers to access your account, even if they know your password. It provides a more effective defense against hacking and unauthorized access to sensitive information.",
  logisticRequestTitle: "Trades to be Shipped",
  logisticRequestDescription:
    "Here you can find all the Trades which needs to be shipped",
  productImage: "Image",
  productDescription: "Product Description",
  productProperties: "Product Properties",
  deliveryInformation: "Delivery Information",
  documents: "Documents",
  title: "Title",
  createRequest: "Create Request",
};
