export default {
  submissionErrorSingle:
    "Beim Bestätigen Ihrer Daten ist ein Fehler aufgetreten",
  submissionErrorMultiple:
    "Beim Bestätigen Ihrer Daten sind COUNT Fehler aufgetreten",
  loadingErrorSingle: "Beim Laden der Daten ist ein Fehler aufgetreten",
  loadingErrorMultiple: "Beim Laden der Daten sind COUNT Fehler aufgetreten",
  oops: "Upps!",
  somethingWentWrong: "Etwas ist schiefgelaufen",
  allFieldsRequired: "Bitte alle Felder ausfüllen",
  pageNotFound: "Nicht gefunden",
  pageNotFoundText:
    "Sorry, wir konnten die von dir gesuchte Seite nicht finden.",
  unexpectedError: "Unerwarteter Fehler",
  unknownError: "Unbekannter Fehler",
  tradeProductUnavailable: "Das Angebot ist nicht mehr verfügbar",
  transportRequestUnavailable: "Die Anfrage ist nicht mehr verfügbar",
};
